<template>
  <div>
    <div class="contain1">
      <div class="contain1_top">
        <div class="h1">订单信息</div>
        <div class="action">
          <div @click="goList()">
            <img src="@/assets/image/icon83.png" alt="" />订单列表
          </div>
        </div>
      </div>
      <div class="contain1_line"></div>
      <div>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon84.png" alt="" />
                商品名称
              </div>
            </template>
            <template>
              <div class="info_style1">{{ JSON.parse(vipOrderInfo.config).vip_name }}</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon85.png" alt="" />
                订单号
              </div>
            </template>
            <template>
              <div class="info_style1">{{ vipOrderInfo.order_no }}</div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon86.png" alt="" />
                下单时间
              </div>
            </template>
            <template>
              <div class="info_style1">{{ vipOrderInfo.create_time }}</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon87.png" alt="" />
                订单状态
              </div>
            </template>
            <template>
              <div class="info_style1" style="color: #ff0000" v-if="vipOrderInfo.status == 1">待支付</div>
              <div class="info_style1" style="color: #67c23a" v-if="vipOrderInfo.status == 2">已支付</div>
              <div class="info_style1" v-if="vipOrderInfo.status == 3">已关闭</div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon88.png" alt="" />
                购买数量
              </div>
            </template>
            <template>
              <div class="info_style1">{{ vipOrderInfo.count }}</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon89.png" alt="" />
                商品单价
              </div>
            </template>
            <template>
              <div class="info_style1" style="color: #ff0000">{{ vipOrderInfo.price_old||0 }}元</div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="1" border>
          <el-descriptions-item
            labelClassName="label2"
            contentClassName="contentClassName2"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon89.png" alt="" />
                实付金额
              </div>
            </template>
            <template>
              <div class="info_style1" style="color: #ff0000">{{ vipOrderInfo.price||0 }}元</div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="contain2">
      <div class="h1">商品信息</div>
      <div class="contain2_line"></div>
      <div>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon90.png" alt="" />
                商品名称
              </div>
            </template>
            <template>
              <div class="info_style1">{{ JSON.parse(vipOrderInfo.config).vip_name }}</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon91.png" alt="" />
                VIP专享折扣
              </div>
            </template>
            <template>
              <div class="info_style1">{{ (JSON.parse(vipOrderInfo.config).discount).toFixed(1) }}折</div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <!-- <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon92.png" alt="" />
                身份标识
              </div>
            </template>
            <template>
              <div class="info_style1">——</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon93.png" alt="" />
                清爽去广告
              </div>
            </template>
            <template>
              <div class="info_style1">——</div>
            </template>
          </el-descriptions-item>
        </el-descriptions> -->
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  name: "vipOrder",
  props: {
    vipOrderInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {
    document.body.style.backgroundColor = "#f6f7f8";
  },
  methods: {
    goList() {
      this.$router.push({
        path: "/personalCenter",
        query: { type: 10 },
      });
    },
  },
};
</script>
    
  <style lang="scss" scoped>
.contain1 {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  .contain1_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .h1 {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.9px;
    }
    .action {
      display: flex;
      align-items: center;
      cursor: pointer;
      div:nth-child(1) {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #23dc5a;
        letter-spacing: 0.9px;
        margin-left: 20px;
        img {
          width: 28px;
          height: 28px;
          margin-right: 6px;
        }
      }
    }
  }
  .contain1_line {
    height: 1px;
    background: #f4f4f4;
    margin: 20px 0;
  }
  .contain1_tip {
    height: 96px;
    background: #fcf5ea;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .contain1_tip_left {
      width: 32px;
      height: 32px;
      margin-right: 20px;
      margin-bottom: 6px;
    }
    .contain1_tip_right {
      div:nth-child(1) {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #edb738;
        letter-spacing: 0.8px;
      }
      div:nth-child(2) {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #edb738;
        letter-spacing: 0.8px;
        span {
          font-size: 20px;
          color: #ff0000;
        }
      }
    }
  }
}
.contain2 {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto 40px;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.9px;
  }
  .contain2_line {
    height: 1px;
    background: #f4f4f4;
    margin: 20px 0;
  }
}
</style>
    <style lang="scss">
.label1 {
  width: 287px;
  height: 85px;
}
.contentClassName1 {
  width: 394px;
}
.label2 {
  width: 287px;
  height: 85px;
}
.contentClassName2 {
  width: 1074px;
}
.label_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #999999;
  letter-spacing: 0.7px;
  margin-left: 20px;
  img {
    width: 25px;
    height: 25px;
    margin-right: 12px;
  }
}
.info_style1 {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.7px;
  margin-left: 20px;
}
</style>