<template>
  <div>
    <vipOrderDetail v-if="type == 2" :vipOrderInfo="info"></vipOrderDetail>
    <docOrderDetail
      v-if="type == 1"
      :document="document"
      :order="order"
    ></docOrderDetail>
  </div>
</template>

<script>
import vipOrderDetail from "@/components/vipOrderDetail.vue";
import docOrderDetail from "@/components/docOrderDetail.vue";
import { get } from "/utils/request";

export default {
  components: {
    vipOrderDetail,
    docOrderDetail,
  },
  data() {
    return {
      type: 0, //2 vip订单详情 1 文档详情
      order_no: 0,
      info: {},
      document: {},
      order: {},
    };
  },
  mounted() {
    if (this.$route.query.type) {
      // this.type = this.$route.query.type;
      this.order_no = this.$route.query.order_no;
      this.getOrderDetail();
    }
  },
  methods: {
    getOrderDetail() {
      console.log(this.$route.query.order_no);
      get("/order/info", { order_no: this.$route.query.order_no }).then(
        (res) => {
          console.log(res);
          if (!res.data.document && !res.data.order) {
            this.info = res.data;
            this.type = res.data.order.type;
          } else {
            this.document = res.data.document;
            this.order = res.data.order;
            this.type = res.data.order.type;

          }

          // if (res.data.order.type == 1) {
          //   this.document = res.data.document;
          //   this.order = res.data.order;
          // }else{
          //   this.info = res.data;
          // }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>